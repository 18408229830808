import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

function Products() {
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const categoryParam = params.get("category");
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [subData, setSubData] = useState(false);
  const [category, setCategory] = useState(categoryParam || "");
  const [search, setSearch] = useState("");
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [count, setCount] = useState();

  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
  };

  const getData = () => {
    axios
      .get(
        `https://earth-spices-api.vercel.app/viewall?category=${category}&search=${search}&page=${currentpage}`
      )
      .then((val) => {
        console.log("val :>> ", val?.data?.spices);
        setData(val?.data?.spices);
        settotalpage(val?.data?.totalPages);
        setcurrentpage(val?.data?.currentPage);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  };

  const getCount = () => {
    axios
      .get("https://earth-spices-api.vercel.app/category-count")
      .then((val) => {
        console.log("val", val?.data);
        setCount(val?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getData();
  }, [category, location.pathname, search, currentpage]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when URL changes
  }, [currentpage]);

  useEffect(() => {
    getCount();
  }, []);
  return (
    <>
      <div
        class="sigma_subheader style-3 bg-cover bg-center dark-overlay"
        style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
      >
        <div class="container">
          <div class="sigma_subheader-inner">
            <h1>Shop</h1>
            <p>
              Cloves, ginger, or pepper. Herbs are derived from green leaves and
              often grow in temperate climates. Common herbs are mint, rosemary.
            </p>
          </div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a class="btn-link" href="#">
                Home
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Products
            </li>
          </ol>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="row masonry">
                {data?.map((val) => {
                  return (
                    <div class="col-md-4 col-sm-6 masonry-item">
                      <div class="sigma_product style-5">
                        <div class="sigma_product-thumb">
                          <img
                            loading="lazy"
                            src={val?.imageUrl}
                            alt="product"
                            style={{ width: "183px", height: "152px" }}
                          />
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#quickViewModal"
                            onClick={() => {
                              openModal(true);
                              setSubData(val);
                            }}
                          >
                            Quick View
                          </a>
                        </div>
                        <div class="sigma_product-body">
                          <h5 class="sigma_product-title">
                            {" "}
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#quickViewModal"
                              onClick={() => {
                                openModal(true);
                                setSubData(val);
                              }}
                            >
                              {val?.spicesName}{" "}
                            </a>{" "}
                          </h5>
                          <div class="sigma_rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                          </div>
                          <p style={{ color: val?.status ? "green" : "red" }}>
                            {val?.status ? "In Stock" : "Out of Stock"}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Pagination
                count={totalpage}
                page={currentpage}
                onChange={onPaginationChange}
                variant="outlined"
                shape="rounded"
                className="pagination_"
              />
            </div>
            <div class="col-lg-4 order-first order-lg-last">
              <div class="widget widget-search">
                <h5 class="widget-title">Search</h5>
                <div class="input-group">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                  />
                  <div class="input-group-append">
                    <button type="button">
                      <i class="fal fa-search mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="sidebar">
                <div class="widget widget-categories">
                  <h5 class="widget-title">Category</h5>
                  <ul>
                    <li>
                      <a href="/Products?category=Spices">
                        Spices
                        <span>{count?.Spices}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=PowderSpices">
                        Powder Spices
                        <span>{count?.PowderSpices}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=OilSeeds">
                        Oil Seeds
                        <span>{count?.OilSeeds}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=Dehydrated">
                        Dehydrated
                        <span>{count?.Dehydrated}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=Fruits">
                        Fruits
                        <span>{count?.Fruits}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar hide-sidebar">
                <div class="widget widget-categories">
                  <h5 class="widget-title">Category</h5>
                  <ul>
                    <li>
                      <a href="/Products?category=Spices">
                        Spices
                        <span>{count?.Spices}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=PowderSpices">
                        Powder Spices
                        <span>{count?.PowderSpices}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=OilSeeds">
                        Oil Seeds
                        <span>{count?.OilSeeds}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=Dehydrated">
                        Dehydrated
                        <span>{count?.Dehydrated}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/Products?category=Fruits">
                        Fruits
                        <span>{count?.Fruits}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
      {showModal && (
        <div className="modal" id="quickViewModal" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row sigma_product-single">
                  <div class="col-md-6">
                    <div class="sigma_product-single-thumb">
                      <img
                        src={subData?.imageUrl}
                        loading="lazy"
                        alt="product"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="sigma_product-single-content">
                      <div class="sigma_rating-wrapper">
                        <div class="sigma_rating">
                          <i class="fas fa-star active"></i>
                          <i class="fas fa-star active"></i>
                          <i class="fas fa-star active"></i>
                          <i class="fas fa-star active"></i>
                          <i class="fas fa-star"></i>
                        </div>
                      </div>
                      <hr />
                      <p class="sigma_product-excerpt">
                        {subData?.description}
                      </p>
                      <div class="sigma_product-meta">
                        <p>
                          <strong>
                            Product : <span>{subData?.spicesName}</span>
                          </strong>
                        </p>
                        <p>
                          <strong>
                            Availablity :{" "}
                            <span
                              style={{
                                color: subData?.status ? "green" : "red",
                              }}
                            >
                              {subData?.status ? "In Stock" : "Out of Stock"}
                            </span>
                          </strong>
                        </p>
                        <p>
                          <strong>
                            Category : <span>{subData?.category}</span>
                          </strong>
                        </p>
                      </div>
                      <hr />
                      <div class="sigma_post-single-meta">
                        <div class="sigma_post-single-meta-item sigma_post-share">
                          <h5>Share</h5>
                          <ul class="sigma_sm">
                            <li>
                              <a
                                href="https://www.facebook.com/boradspices"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i class="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i class="fab fa-youtube"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Products;
